export const content = {
    en: {
        detailed: 'More',
        main: 'Main',
        aboutUs: 'About Us',
        gallery: 'Gallery',
        wines: 'Wines',
        contact: 'Contact',
        navigation: 'Navigation',
        wine: 'wine',
        type: 'Type',
        region: 'Region',
        alcoholPercentage: 'Alcohol percentage',
        method: 'Method',
        color: 'Color',
        year: 'Year',
        grape: 'Grape',
        pairings: 'Pairings',
        medals: 'Medals',
        locations: 'Stores',
        name: 'Name',
        brand: 'Brand',
        notFound: "This page doesn't exist",
        back: 'Take me back',
        filter: 'Filter',
        message: 'How can we help you?',
        send: 'Send',
        yourName: 'Your name',
        contactErrorName: 'Please enter your name',
        contactErrorEmail: 'Please enter your E-Mail',
        contactErrorMessage: 'Please enter your message',
        contactErrorUnexpected: 'Unexpected error! Please try again later',
        contactSuccess: 'Success! We have received your email and will get back to you shortly',
    },
    ge: {
        detailed: 'დეტალურად',
        main: 'მთავარი',
        aboutUs: 'ჩვენ შესახებ',
        gallery: 'გალერეა',
        wines: 'ღვინოები',
        contact: 'კონტაქტი',
        navigation: 'ნავიგაცია',
        wine: 'ღვინო',
        type: 'ტიპი',
        region: 'რეგიონი',
        alcoholPercentage: 'ალკოჰოლის შემცველობა',
        method: 'დამზადების მეთოდი',
        color: 'ფერი',
        year: 'ჩამოსხმის წელი',
        grape: 'ყურძენი',
        pairings: 'მისაყოლებლები',
        medals: 'მედლები',
        locations: 'გაყიდვის ადგილები',
        name: 'სახელი',
        brand: 'ბრენდი',
        notFound: 'ეს გვერდი არ არსებობს',
        back: 'უკან დაბრუნება',
        filter: 'ფილტრი',
        message: 'რითი შეგვიძლია დაგეხმაროთ?',
        send: 'გაგზავნა',
        yourName: 'თქვენი სახელი',
        contactErrorName: 'გთხოვთ შეიყვანოთ თქვენი სახელი',
        contactErrorEmail: 'გთხოვთ შეიყვანოთ ელ-ფოსტა',
        contactErrorMessage: 'გთხოვთ შეავსეთ მესიჯის ველი',
        contactErrorUnexpected: 'მოულოდნელი შეცდომა! გთხოვთ სცადეთ თავიდან',
        contactSuccess: 'ჩვენ მივიღეთ თქვენი შეტყობინება და გიპასუხებთ რაც შეიძლება მალე!',
    },
    ru: {
        detailed: 'детальнее',
        main: 'главная',
        aboutUs: 'о нас',
        gallery: 'галерея',
        wines: 'Вина',
        contact: 'контакт',
        navigation: 'навигация',
        wine: 'вино',
        type: 'тип',
        region: 'область',
        alcoholPercentage: 'Процент алкоголя',
        method: 'метод',
        color: 'цвет',
        year: 'год',
        grape: 'виноград',
        pairings: 'подходит к',
        medals: 'медали',
        locations: 'места покупки',
        name: 'название',
        brand: 'бренд',
        notFound: 'эта страница не существует',
        back: 'возьми меня обратно',
        filter: 'фильтр',
        message: 'Как мы можем тебе помочь?',
        send: 'Отправить',
        yourName: 'ваше имя',
        contactErrorName: 'Пожалуйста, введите Ваше имя',
        contactErrorEmail: 'Пожалуйста, введите ваш E-Mail',
        contactErrorMessage: 'Пожалуйста, введите ваше сообщение',
        contactErrorUnexpected: 'Непредвиденная ошибка! Пожалуйста, попробуйте позже',
        contactSuccess: 'Мы получили ваше письмо и свяжемся с вами в ближайшее время!',
    },
};
