import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../App';
import { content } from '../content';

interface Props {}

export const NotFoundPage: React.FC<Props> = () => {
    const { lang } = useContext(LanguageContext);
    const history = useHistory();

    const handleBackButton = () => {
        history.push('/');
    };

    return (
        <div className="gw-section-lg">
            <div className="gw-404-wrapper">
                <h1>{content[lang].notFound}</h1>
                <button className="gw-button secondary" onClick={handleBackButton}>
                    {content[lang].back}
                </button>
            </div>
        </div>
    );
};
