import React from 'react';

interface Props {}

export const Loading: React.FC<Props> = () => {
    return (
        <div className="gw-loading-wrapper">
            <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
