import React, { useContext, useMemo } from 'react';
import { LanguageContext } from '../App';
import { WineBrowser } from '../components/WineBrowser';
import {
    Copies,
    useWineBrands,
    useWineColors,
    useWineGrapes,
    useWineMedals,
    useWineMethods,
    useWinePairings,
    useWines,
    useWineTypes,
} from '../hooks/wines';
import { LoadingSection } from '../utils/LoadingSection';
import { usePageView } from '../hooks/pageView';

interface Props {
    copies: Copies;
}

export const WineBrowserPage: React.FC<Props> = ({ copies }) => {
    usePageView();
    const { lang } = useContext(LanguageContext);
    const wines = useWines();
    const wineTypes = useWineTypes();
    const winePairings = useWinePairings();
    const wineColors = useWineColors();
    const wineGrapes = useWineGrapes();
    const wineMedals = useWineMedals();
    const wineBrands = useWineBrands();
    const wineMethods = useWineMethods();

    const browserTypes = useMemo(() => {
        return wineTypes
            ? wineTypes.map(wineType => ({
                  label: wineType[`name_${lang}`],
                  value: wineType.id,
              }))
            : [];
    }, [lang, wineTypes]);

    const browserPairings = useMemo(() => {
        return winePairings
            ? winePairings.map(winePairing => ({
                  label: winePairing[`name_${lang}`],
                  value: winePairing.id,
              }))
            : [];
    }, [lang, winePairings]);

    const browserColors = useMemo(() => {
        return wineColors
            ? wineColors.map(wineColor => ({
                  name: wineColor[`name_${lang}`],
                  id: wineColor.id,
                  hex: wineColor.hex_color,
              }))
            : [];
    }, [lang, wineColors]);

    const browserGrapes = useMemo(() => {
        return wineGrapes
            ? wineGrapes.map(wineGrape => ({
                  label: wineGrape[`name_${lang}`],
                  value: wineGrape.id,
              }))
            : [];
    }, [lang, wineGrapes]);

    const browserMedals = useMemo(() => {
        return wineMedals
            ? wineMedals.map(wineMedal => ({
                  label: wineMedal[`name_${lang}`],
                  value: wineMedal.id,
              }))
            : [];
    }, [lang, wineMedals]);

    const browserBrands = useMemo(() => {
        return wineBrands
            ? wineBrands.map(wineBrand => ({
                  label: wineBrand[`name_${lang}`],
                  value: wineBrand.id,
              }))
            : [];
    }, [lang, wineBrands]);

    const browserMethods = useMemo(() => {
        return wineMethods
            ? wineMethods.map(wineMethod => ({
                  label: wineMethod[`name_${lang}`],
                  value: wineMethod.id,
              }))
            : [];
    }, [lang, wineMethods]);

    if (
        !wines ||
        !wineTypes ||
        !winePairings ||
        !wineColors ||
        !wineGrapes ||
        !wineMedals ||
        !wineBrands ||
        !wineMethods
    )
        return <LoadingSection />;

    return (
        <WineBrowser
            wines={wines}
            wineTypes={browserTypes}
            winePairings={browserPairings}
            wineColors={browserColors}
            wineGrapes={browserGrapes}
            wineMedals={browserMedals}
            wineBrands={browserBrands}
            wineMethods={browserMethods}
            title={copies[lang].winebrowser_title}
        />
    );
};
