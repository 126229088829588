import Axios from 'axios';
import { languages } from '../App';

export interface APIWineLocation {
    id: string;
    name_en: string | null;
    name_ge: string | null;
    name_ru: string | null;
    number: string | null;
    created_at: string | null;
    updated_at: string | null;
    address_ge: string | null;
    address_en: string | null;
    address_ru: string | null;
    location: string | null;
    geoJSON: {
        type: string;
        coordinates: [number, number];
    };
}

export interface APIWineBrand {
    id: string;
    name_en: string | null;
    name_ge: string | null;
    name_ru: string | null;
    description_en: string | null;
    description_ge: string | null;
    description_ru: string | null;
    logo: string | null;
    logoImageLink: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface APIWineGrape {
    id: string;
    name_en: string | null;
    name_ge: string | null;
    name_ru: string | null;
    region_en: string | null;
    region_ge: string | null;
    region_ru: string | null;
    percentage: number | null;
    hex_color: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface APIWineMedal {
    id: string;
    name_en: string | null;
    name_ge: string | null;
    name_ru: string | null;
    image: string | null;
    year: number | null;
    created_at: string | null;
    updated_at: string | null;
    imageLink: string | null;
}

export interface APIWinePairing {
    id: string;
    name_en: string | null;
    name_ge: string | null;
    name_ru: string | null;
    image: string | null;
    created_at: string | null;
    updated_at: string | null;
    imageLink: string | null;
}

export interface APIWineColor {
    id: string;
    name_en: string | null;
    name_ge: string | null;
    name_ru: string | null;
    hex_color: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface APIWineMethod {
    id: string;
    name_en: string | null;
    name_ge: string | null;
    name_ru: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface APIWineType {
    id: string;
    name_en: string | null;
    name_ge: string | null;
    name_ru: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface APIWine {
    id: string;
    name_en: string | null;
    name_ge: string | null;
    name_ru: string | null;
    description_en: string | null;
    description_ge: string | null;
    description_ru: string | null;
    region_en: string | null;
    region_ge: string | null;
    region_ru: string | null;
    stock: number | null;
    main_image: string | null;
    mainImageLink: string | null;
    alcohol_amount: number | null;
    created_at: string | null;
    updated_at: string | null;
    wine_type: APIWineType | null;
    wine_method: APIWineMethod | null;
    color: APIWineColor | null;
    pairings: APIWinePairing[];
    medals: APIWineMedal[];
    grapes: APIWineGrape[];
    locations: APIWineLocation[];
    brands: [APIWineBrand];
    bottling_year: number | null;
}

export interface APICopy {
    id: string;
    calltoaction_language: languages;
    calltoaction_header: string | null;
    calltoaction_description: string | null;
    calltoaction_button: string | null;
    calltoaction_image: string | null;
    callToActionImageLink: string | null;
    winecarousel_title: string | null;
    winecarousel_description: string | null;
    aboutUsImageLink: string | null;
    aboutus_description_one: string | null;
    aboutus_description_two: string | null;
    aboutus_title: string | null;
    contact_title: string | null;
    contact_description: string | null;
    footer_description: string | null;
    winebrowser_title: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface APISocial {
    id: string;
    link: string | null;
    image: string | null;
    created_at: string | null;
    updated_at: string | null;
    imageLink: string | null;
}

export interface APIGallery {
    id: string;
    image: string;
    created_at: string | null;
    updated_at: string | null;
    imageLink: string;
}

export class WinesController {
    getWines = (): Promise<APIWine[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/wines`).then(res => res.data);

    getWine = (wineId: string): Promise<APIWine> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/wines`, {
            params: {
                id: wineId,
            },
        }).then(res => res.data);

    getWineBrands = (): Promise<APIWineBrand[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/brands`).then(res => res.data);

    getWineColors = (): Promise<APIWineColor[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/colors`).then(res => res.data);

    getWineGrapes = (): Promise<APIWineGrape[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/grapes`).then(res => res.data);

    getWineLocations = (): Promise<APIWineLocation[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/locations`).then(res => res.data);

    getWineMedals = (): Promise<APIWineMedal[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/medals`).then(res => res.data);

    getWinePairings = (): Promise<APIWinePairing[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/pairings`).then(res => res.data);

    getWineMethods = (): Promise<APIWineMethod[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/winemethods`).then(res => res.data);

    getWineTypes = (): Promise<APIWineType[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/winetypes`).then(res => res.data);

    getCopies = (): Promise<APICopy[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/copies`).then(res => res.data);

    getSocials = (): Promise<APISocial[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/socials`).then(res => res.data);

    getGallery = (): Promise<APIGallery[]> =>
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/images`).then(res => res.data);

    postContact = (body: { name: string; email: string; message: string }) =>
        Axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/submitcontactrequest`, body);
}
