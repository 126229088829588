import { Snackbar } from '@material-ui/core';
import React from 'react';

interface Props {
    open: boolean;
    state: 'success' | 'fail';
    message: string;
}

export const Notification: React.FC<Props> = ({ open, state, message }) => {
    return (
        <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <div className={`gw-notification ${state === 'success' ? 'success' : 'fail'}`}>
                <p className="secondary white">{message}</p>
                <div style={{ display: 'flex' }}>
                    {state === 'success' ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#FFF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                            <polyline points="22 4 12 14.01 9 11.01" />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />
                            <line x1="12" y1="8" x2="12" y2="12" />
                            <line x1="12" y1="16" x2="12.01" y2="16" />
                        </svg>
                    )}
                </div>
            </div>
        </Snackbar>
    );
};
