import { useEffect, useState } from 'react';
import {
    APIWine,
    WinesController,
    APIWineBrand,
    APIWineColor,
    APIWineGrape,
    APIWineLocation,
    APIWineMedal,
    APIWinePairing,
    APIWineMethod,
    APIWineType,
    APICopy,
    APISocial,
    APIGallery,
} from '../api/controller';
import { languages } from '../App';
import { keyBy } from 'lodash';
import { useHistory } from 'react-router-dom';

export const useLang = () => {
    const [lang, setLanguage] = useState<languages>(
        (localStorage.getItem('language') as languages) || 'ge'
    );

    const setLang = (lang: languages) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
    };

    return { lang, setLang };
};

export const useWines = () => {
    const [wines, setWines] = useState<APIWine[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getWines().then(res => {
            if (isCancelled) return;
            setWines(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return wines;
};

export const useWine = (wineId: string) => {
    const [wine, setWine] = useState<APIWine>();
    const history = useHistory();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller
            .getWine(wineId)
            .then(res => {
                if (isCancelled) return;
                setWine(res);
            })
            .catch(e => {
                history.push('/404');
                console.error(e);
            });

        return () => {
            isCancelled = true;
        };
    }, [wineId, history]);

    return wine;
};

export const useWineBrands = () => {
    const [brands, setBrands] = useState<APIWineBrand[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getWineBrands().then(res => {
            if (isCancelled) return;
            setBrands(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return brands;
};

export const useWineColors = () => {
    const [colors, setColors] = useState<APIWineColor[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getWineColors().then(res => {
            if (isCancelled) return;
            setColors(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return colors;
};

export const useWineGrapes = () => {
    const [grapes, setGrapes] = useState<APIWineGrape[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getWineGrapes().then(res => {
            if (isCancelled) return;
            setGrapes(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return grapes;
};

export const useWineLocations = () => {
    const [locations, setLocations] = useState<APIWineLocation[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getWineLocations().then(res => {
            if (isCancelled) return;
            setLocations(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return locations;
};

export const useWineMedals = () => {
    const [medals, setMedals] = useState<APIWineMedal[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getWineMedals().then(res => {
            if (isCancelled) return;
            setMedals(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return medals;
};

export const useWinePairings = () => {
    const [pairings, setPairings] = useState<APIWinePairing[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getWinePairings().then(res => {
            if (isCancelled) return;
            setPairings(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return pairings;
};

export const useWineMethods = () => {
    const [methods, setMethods] = useState<APIWineMethod[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getWineMethods().then(res => {
            if (isCancelled) return;
            setMethods(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return methods;
};

export const useWineTypes = () => {
    const [types, setTypes] = useState<APIWineType[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getWineTypes().then(res => {
            if (isCancelled) return;
            setTypes(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return types;
};

export const useCopies = () => {
    const [copies, setCopies] = useState<Copies>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getCopies().then(res => {
            if (isCancelled) return;
            setCopies(keyBy(res, 'calltoaction_language'));
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return copies;
};

export const useSocials = () => {
    const [socials, setSocials] = useState<APISocial[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getSocials().then(res => {
            if (isCancelled) return;
            setSocials(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return socials;
};

export const useGallery = () => {
    const [gallery, setGallery] = useState<APIGallery[]>();

    useEffect(() => {
        let isCancelled = false;
        const controller = new WinesController();

        controller.getGallery().then(res => {
            if (isCancelled) return;
            setGallery(res);
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    return gallery;
};

export interface Copies {
    [key: string]: APICopy;
}
