import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { NavButtons } from './NavButtons';
import Fade from 'react-reveal/Fade';
import { useSwipeable } from 'react-swipeable';
import { Link, useHistory } from 'react-router-dom';

interface Wine {
    name: string | null;
    description: string | null;
    imgSrc: string | null;
    id: string;
}

interface Props {
    brandName: string;
    brandLogoSrc: string;
    brandDescription: string;
    wines: Wine[];
    callToAction: string;
    slideInterval?: number;
    flip?: boolean;
}

export const BrandShowcase: React.FC<Props> = ({
    brandName,
    brandLogoSrc,
    brandDescription,
    wines,
    callToAction,
    slideInterval,
    flip,
}) => {
    const [currentWineIndex, setCurrentWineIndex] = useState(0);
    const [didInteract, setDidInteract] = useState(false);
    const history = useHistory();

    const nextWine = useCallback(() => {
        setCurrentWineIndex(currentWineIndex === wines.length - 1 ? 0 : currentWineIndex + 1);
    }, [currentWineIndex, wines]);

    const prevWine = useCallback(() => {
        setCurrentWineIndex(currentWineIndex === 0 ? wines.length - 1 : currentWineIndex - 1);
    }, [currentWineIndex, wines]);

    const handleNextWine = () => {
        setDidInteract(true);
        nextWine();
    };

    const handlePrevWine = () => {
        setDidInteract(true);
        prevWine();
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNextWine,
        onSwipedRight: handlePrevWine,
        trackMouse: true,
        trackTouch: true,
    });

    const handleNextWineAuto = useCallback(() => {
        if (window.innerWidth < 960) return;
        nextWine();
    }, [nextWine]);

    const handleWineImageClick = () => {
        history.push(`/wine/${wines[currentWineIndex].id}`);
    };

    useEffect(() => {
        if (!slideInterval || didInteract) return;
        const wineInterval = setInterval(handleNextWineAuto, slideInterval * 1000);
        return () => clearInterval(wineInterval);
    }, [handleNextWineAuto, slideInterval, didInteract]);

    if (!wines.length) return null;

    return (
        <section className="gw-brand-showcase-wrapper gw-section-lg">
            <div className="gw-container-lg">
                <Grid container spacing={0} className={`${flip ? 'reverse' : ''}`}>
                    <Grid item xs={12} md={6}>
                        <div className="gw-brand-showcase">
                            <div className="gw-brand-info">
                                <div className="gw-brand-header">
                                    <h3 className="gw-brand-name">{brandName}</h3>
                                    <img src={brandLogoSrc} alt="logo" className="gw-brand-logo" />
                                </div>
                                <div className="gw-brand-hr">
                                    <Fade left delay={0}>
                                        <hr />
                                    </Fade>
                                </div>
                                <p className="gw-brand-description">{brandDescription}</p>
                            </div>
                            <div className="gw-brand-wine-info">
                                <Fade spy={currentWineIndex} delay={0} appear top>
                                    <h5>{wines[currentWineIndex].name}</h5>
                                </Fade>
                                <Fade spy={currentWineIndex} delay={300} appear>
                                    <p className="gw-brand-wine-description">
                                        {wines[currentWineIndex].description}
                                    </p>
                                </Fade>
                                <Link
                                    to={`/wine/${wines[currentWineIndex].id}`}
                                    className="gw-button secondary"
                                >
                                    {callToAction}
                                </Link>
                            </div>
                            <div className="gw-brand-navigation-wrapper">
                                <div className={`gw-brand-navigation ${flip ? 'left' : 'right'}`}>
                                    <NavButtons onNext={handleNextWine} onPrev={handlePrevWine} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={5}>
                        <div className="gw-brand-wine-image-wrapper" onClick={handleWineImageClick}>
                            <div className="gw-brand-wines" {...swipeHandlers}>
                                {wines.map(wine => (
                                    <div
                                        key={wine.id}
                                        className="gw-brand-wine-image-slider"
                                        style={{
                                            transform: `translateX(-${currentWineIndex * 100}%)`,
                                        }}
                                    >
                                        <img
                                            draggable="false"
                                            src={wine.imgSrc || ''}
                                            alt="wine showcase"
                                            className="gw-brand-wine-image"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};
