import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { NavButtons } from './NavButtons';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';

interface Wine {
    name: string | null;
    imgSrc: string | null;
    id: string;
}

interface Props {
    wines: Wine[];
    title: string | null;
    description: string | null;
    autoPlaySpeed: number;
}

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 840 },
        items: 3,
    },
    largeMobile: {
        breakpoint: { max: 840, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const ButtonGroup = ({ next, previous }: any) => {
    return (
        <div className="gw-wine-carousel-arrows-wrapper">
            <div className="gw-wine-carousel-arrows">
                <NavButtons onNext={next} onPrev={previous} />
            </div>
        </div>
    );
};

export const WineCarousel: React.FC<Props> = ({ wines, title, description, autoPlaySpeed }) => {
    const history = useHistory();

    const handleWineClick = (wineId: string) => {
        history.push(`/wine/${wineId}`);
    };

    return (
        <section className="gw-section-lg gw-wine-carousel-outer-wrapper">
            <div className="gw-container-lg">
                <Fade delay={0}>
                    <h3 className="gw-wine-carousel-title">{title}</h3>
                </Fade>
                <Fade delay={300}>
                    <p className="gw-wine-carousel-description">{description}</p>
                </Fade>
                <div className="gw-wine-carousel-inner-wrapper">
                    <Carousel
                        responsive={responsive}
                        swipeable
                        autoPlaySpeed={autoPlaySpeed * 1000}
                        infinite
                        autoPlay
                        customButtonGroup={<ButtonGroup />}
                        renderButtonGroupOutside
                        arrows={false}
                    >
                        {wines.map(wine => (
                            <div
                                className="gw-wine-carousel-item"
                                key={wine.id}
                                onClick={() => handleWineClick(wine.id)}
                            >
                                <img
                                    src={wine.imgSrc || ''}
                                    alt={wine.name || ''}
                                    className="gw-wine-carousel-item-image"
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </section>
    );
};
