import React from 'react';

export interface ColorItem {
    name: string | null;
    hex: string | null;
    id: string;
}

interface IProps {
    value: string[];
    colors: ColorItem[];
    onChange: (newValue: string[]) => void;
}

export const WineColorSelect: React.FC<IProps> = ({ value, colors, onChange }) => {
    const handleClick = (colorId: string) => {
        const newValue = value.includes(colorId)
            ? value.filter(val => val !== colorId)
            : [...value, colorId];
        onChange(newValue);
    };

    return (
        <div className="gw-wine-color-select-wrapper">
            {colors.map(color => {
                return color.hex ? (
                    <div
                        style={{ backgroundColor: color.hex || '#000' }}
                        className={`gw-wine-color-item ${
                            value.includes(color.id) ? 'selected' : ''
                        }`}
                        onClick={() => handleClick(color.id)}
                        key={color.id}
                    ></div>
                ) : null;
            })}
        </div>
    );
};
