import React, { useEffect, useState, useCallback } from 'react';
import scrollTipSVG from '../assets/img/scrollTip.svg';
import Grid from '@material-ui/core/Grid';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

interface CallToAction {
    text: string | null;
    href: string;
}

interface Props {
    header: string | null;
    description: string | null;
    callToAction: CallToAction;
    backgroundSrc: string | null;
}

export const MainScreen: React.FC<Props> = ({
    header,
    description,
    callToAction,
    backgroundSrc,
}) => {
    const [didScroll, setDidScroll] = useState(false);

    const handleScroll = useCallback(() => {
        setDidScroll(window.pageYOffset < 200 ? false : true);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <main
            className="gw-main-screen-wrapper"
            style={{ backgroundImage: `url("${backgroundSrc}")` }}
        >
            <div className="gw-container-lg">
                <div className="gw-call-to-action">
                    <Grid container spacing={0}>
                        <Grid item xs={12} lg={6}>
                            <Fade delay={0} duration={2000}>
                                <h1 className="primary white gw-cta-header">{header}</h1>
                            </Fade>
                            <Fade delay={300} duration={3000}>
                                <p className="secondary white gw-cta-description">{description}</p>
                                <Link className="gw-button wide" to={callToAction.href}>
                                    {callToAction.text}
                                </Link>
                            </Fade>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <img
                src={scrollTipSVG}
                alt="scroll down"
                className={`gw-scroll-tip ${didScroll ? 'hidden' : ''}`}
            />
        </main>
    );
};
