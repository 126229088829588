import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

const initGA = (trackingID: string) => {
    ReactGA.initialize(trackingID);
};

const pageView = () => {
    initGA(process.env.REACT_APP_GOOGLE_API_KEY as string);
    ReactGA.pageview(window.location.pathname + window.location.search);

    initPixel(process.env.REACT_APP_PIXEL_ID as string);
    ReactPixel.pageView();
};

const event = (category: string, action: string) => {
    ReactGA.event({
        category: category,
        action: action,
    });
};

const initPixel = (pixelId: string) => {
    const options = {
        autoConfig: true,
        debug: true,
    };
    ReactPixel.init(pixelId, undefined, options);
};

export { initGA, pageView, event };
