import React from 'react';
import { Grid } from '@material-ui/core';
import Fade from 'react-reveal/Fade';

interface Props {
    title: string | null;
    descriptionColOne: string | null;
    descriptionColTwo: string | null;
    imageSrc: string | null;
}

export const AboutUs: React.FC<Props> = ({
    title,
    descriptionColOne,
    descriptionColTwo,
    imageSrc,
}) => {
    return (
        <section className="gw-about-us-wrapper gw-section-lg" id="about-us">
            <div className="gw-container-lg">
                <Grid container spacing={0}>
                    <Grid item md={5} xs={12}>
                        <div className="gw-about-us-text">
                            <Fade delay={0}>
                                <h3>{title}</h3>
                            </Fade>
                            <Grid container spacing={3}>
                                <Grid item lg={6} md={12}>
                                    <Fade up delay={300}>
                                        <p>{descriptionColOne}</p>
                                    </Fade>
                                </Grid>
                                <Grid item lg={6} md={12}>
                                    <Fade up delay={500}>
                                        <p>{descriptionColTwo}</p>
                                    </Fade>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item md={6} xs={12}>
                        <div className="gw-about-us-image-wrapper">
                            <img
                                loading="lazy"
                                src={imageSrc || ''}
                                alt="about us"
                                className="gw-about-us-image"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};
