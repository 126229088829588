import { Grid } from '@material-ui/core';
import React, { useContext, useMemo, useState } from 'react';
import { APIWine } from '../api/controller';
import { LanguageContext } from '../App';
import { content } from '../content';
import { CheckboxOption, CheckboxSelect } from './CheckboxSelect';
import { SidebarItemCollapsible } from './SidebarItemCollapsible';
import { ColorItem, WineColorSelect } from './WineColorSelect';
import { WineList } from './WineList';

interface Props {
    wines: APIWine[];
    wineTypes: CheckboxOption[];
    winePairings: CheckboxOption[];
    wineColors: ColorItem[];
    wineGrapes: CheckboxOption[];
    wineMedals: CheckboxOption[];
    wineBrands: CheckboxOption[];
    wineMethods: CheckboxOption[];
    title: string | null;
}

export const WineBrowser: React.FC<Props> = ({
    wines,
    wineTypes,
    winePairings,
    wineColors,
    wineGrapes,
    wineMedals,
    wineBrands,
    wineMethods,
    title,
}) => {
    const { lang } = useContext(LanguageContext);
    const [isFilterToggled, setIsFilterToggled] = useState(window.innerWidth > 960);
    const [nameFilter, setNameFilter] = useState('');

    const [typeFilter, setTypeFilter] = useState<string[]>([]);
    const [pairingFilter, setPairingFilter] = useState<string[]>([]);
    const [colorFilter, setColorFilter] = useState<string[]>([]);
    const [grapeFilter, setGrapeFilter] = useState<string[]>([]);
    const [medalFilter, setMedalFilter] = useState<string[]>([]);
    const [brandFilter, setBrandFilter] = useState<string[]>([]);
    const [methodFilter, setMethodFilter] = useState<string[]>([]);

    const transformedWines = useMemo(() => {
        return wines
            ? wines.map(wine => ({
                  id: wine.id,
                  name: wine[`name_${lang}`],
                  specification:
                      wine?.wine_type && wine.color
                          ? `${wine.color[`name_${lang}`]} ${wine.wine_type[`name_${lang}`]} ${
                                content[lang].wine
                            }`
                          : '',
                  imgSrc: `${wine.mainImageLink}`,
                  typeId: wine.wine_type && wine.wine_type.id,
                  colorId: wine.color && wine.color.id,
                  methodId: wine.wine_method && wine.wine_method.id,
                  brandId: wine.brands[0].id,
                  pairingIds: wine.pairings.map(pairing => pairing.id),
                  medalIds: wine.medals.map(medal => medal.id),
                  grapeIds: wine.grapes.map(grape => grape.id),
              }))
            : [];
    }, [lang, wines]);

    const winesFilteredByName = useMemo(() => {
        return transformedWines.filter(
            wine =>
                wine.name?.toLowerCase().includes(nameFilter) ||
                wine.specification?.toLowerCase().includes(nameFilter)
        );
    }, [nameFilter, transformedWines]);

    const winesFilteredByType = useMemo(() => {
        return typeFilter.length
            ? winesFilteredByName.filter(wine => typeFilter.includes(wine.typeId!))
            : winesFilteredByName;
    }, [typeFilter, winesFilteredByName]);

    const winesFilteredByBrand = useMemo(() => {
        return brandFilter.length
            ? winesFilteredByType.filter(wine => brandFilter.includes(wine.brandId!))
            : winesFilteredByType;
    }, [brandFilter, winesFilteredByType]);

    const winesFilteredByColor = useMemo(() => {
        return colorFilter.length
            ? winesFilteredByBrand.filter(wine => colorFilter.includes(wine.colorId!))
            : winesFilteredByBrand;
    }, [colorFilter, winesFilteredByBrand]);

    const winesFilteredByMethod = useMemo(() => {
        return methodFilter.length
            ? winesFilteredByColor.filter(wine => methodFilter.includes(wine.methodId!))
            : winesFilteredByColor;
    }, [methodFilter, winesFilteredByColor]);

    const winesFilteredByPairing = useMemo(() => {
        return pairingFilter.length
            ? winesFilteredByMethod.filter(wine =>
                  wine.pairingIds.some(pairingId => pairingFilter.includes(pairingId))
              )
            : winesFilteredByMethod;
    }, [pairingFilter, winesFilteredByMethod]);

    const winesFilteredByMedal = useMemo(() => {
        return medalFilter.length
            ? winesFilteredByPairing.filter(wine =>
                  wine.medalIds.some(medalId => medalFilter.includes(medalId))
              )
            : winesFilteredByPairing;
    }, [medalFilter, winesFilteredByPairing]);

    const winesFilteredByGrape = useMemo(() => {
        return grapeFilter.length
            ? winesFilteredByMedal.filter(wine =>
                  wine.grapeIds.some(grapeId => grapeFilter.includes(grapeId))
              )
            : winesFilteredByMedal;
    }, [grapeFilter, winesFilteredByMedal]);

    const handleFilterToggle = () => {
        setIsFilterToggled(!isFilterToggled);
    };

    return (
        <section className="gw-wine-browser-wrapper">
            <div className="gw-container-lg">
                <Grid container spacing={0}>
                    <Grid item xs={12} md={3}>
                        <div
                            className="gw-wine-browser-sidebar-toggle"
                            onClick={handleFilterToggle}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke={isFilterToggled ? '#1a202c' : '#9e9e9e'}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="gw-wine-browser-sidebar-toggle-icon"
                            >
                                <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                            </svg>
                            <h5 className="gw-wine-browser-sidebar-toggle-text">
                                {content[lang].filter}
                            </h5>
                        </div>
                        {isFilterToggled && (
                            <div className="gw-wine-browser-sidebar-wrapper">
                                <SidebarItemCollapsible title={content[lang].name}>
                                    <input
                                        type="text"
                                        className="gw-wine-browser-name-input"
                                        placeholder={content[lang].name}
                                        value={nameFilter}
                                        onChange={e => setNameFilter(e.target.value)}
                                    />
                                </SidebarItemCollapsible>
                                {!!wineBrands.length && (
                                    <SidebarItemCollapsible title={content[lang].brand}>
                                        <CheckboxSelect
                                            value={brandFilter}
                                            options={wineBrands}
                                            onChange={newValue => setBrandFilter(newValue)}
                                        />
                                    </SidebarItemCollapsible>
                                )}
                                {!!wineTypes.length && (
                                    <SidebarItemCollapsible title={content[lang].type}>
                                        <CheckboxSelect
                                            value={typeFilter}
                                            options={wineTypes}
                                            onChange={newValue => setTypeFilter(newValue)}
                                        />
                                    </SidebarItemCollapsible>
                                )}
                                {!!wineColors.length && (
                                    <SidebarItemCollapsible title={content[lang].color}>
                                        <WineColorSelect
                                            value={colorFilter}
                                            colors={wineColors}
                                            onChange={e => setColorFilter(e)}
                                        />
                                    </SidebarItemCollapsible>
                                )}
                                {!!winePairings.length && (
                                    <SidebarItemCollapsible title={content[lang].pairings}>
                                        <CheckboxSelect
                                            value={pairingFilter}
                                            options={winePairings}
                                            onChange={newValue => setPairingFilter(newValue)}
                                        />
                                    </SidebarItemCollapsible>
                                )}
                                {!!wineMedals.length && (
                                    <SidebarItemCollapsible title={content[lang].medals}>
                                        <CheckboxSelect
                                            value={medalFilter}
                                            options={wineMedals}
                                            onChange={newValue => setMedalFilter(newValue)}
                                        />
                                    </SidebarItemCollapsible>
                                )}
                                {!!wineMethods.length && (
                                    <SidebarItemCollapsible title={content[lang].method}>
                                        <CheckboxSelect
                                            value={methodFilter}
                                            options={wineMethods}
                                            onChange={newValue => setMethodFilter(newValue)}
                                        />
                                    </SidebarItemCollapsible>
                                )}
                                {!!wineGrapes.length && (
                                    <SidebarItemCollapsible title={content[lang].grape}>
                                        <CheckboxSelect
                                            value={grapeFilter}
                                            options={wineGrapes}
                                            onChange={newValue => setGrapeFilter(newValue)}
                                        />
                                    </SidebarItemCollapsible>
                                )}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <WineList title={title} wines={winesFilteredByGrape} />
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};
