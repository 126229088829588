import React from 'react';
import { Link } from 'react-router-dom';

interface Wine {
    id: string;
    name: string | null;
    specification: string | null;
    imgSrc: string | null;
}

interface Props {
    wine: Wine;
}

export const WineItem: React.FC<Props> = ({ wine }) => {
    return (
        <div className="gw-wine-item-wrapper">
            <Link to={`/wine/${wine.id}`}>
                <div className="gw-wine-item-image-wrapper">
                    <img
                        src={wine.imgSrc || ''}
                        alt={wine.name || ''}
                        className="gw-wine-item-image"
                    />
                </div>
            </Link>
            <h5 className="gw-wine-item-name">
                <Link to={`/wine/${wine.id}`}>{wine.name}</Link>
            </h5>
            <p>{wine.specification}</p>
        </div>
    );
};
