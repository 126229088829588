import React, { useMemo } from 'react';
import { Gallery } from '../components/Gallery';
import { useGallery } from '../hooks/wines';
import { LoadingSection } from '../utils/LoadingSection';
import { usePageView } from '../hooks/pageView';

interface Props {}

export const GalleryPage: React.FC<Props> = () => {
    usePageView();
    const gallery = useGallery();

    const galleryImages = useMemo(() => {
        return gallery?.map(item => item.imageLink);
    }, [gallery]);

    if (!galleryImages) return <LoadingSection />;

    return <Gallery images={galleryImages} />;
};
