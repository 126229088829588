import React, { useMemo, useState, useCallback, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import lightLogoSVG from './assets/img/gw-logo-white.svg';
import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { useCopies, useLang, useSocials } from './hooks/wines';
import { ScrollToTop } from './utils/ScrollToTop';
import { content } from './content';
import { WinePage } from './pages/WinePage';
import { GalleryPage } from './pages/GalleryPage';
import { WineBrowserPage } from './pages/WineBrowserPage';
import { HomePage } from './pages/HomePage';
import { SplashScreen } from './utils/SplashScreen';
import { NotFoundPage } from './pages/NotFoundPage';
import { Notification } from './utils/Notification';

export type languages = 'ge' | 'en' | 'ru';

interface LanguageContext {
    lang: languages;
    setLang: (lang: languages) => void;
}

interface NotificationContext {
    showNotification: (message: string, state: 'success' | 'fail', timeout: number) => void;
}

export const LanguageContext = React.createContext({} as LanguageContext);
export const NotificationContext = React.createContext({} as NotificationContext);

export const App = () => {
    const { lang, setLang } = useLang();
    const copies = useCopies();
    const socials = useSocials();
    const [notificationState, setNotificationState] = useState({
        open: false,
        message: '',
        state: 'success' as 'success' | 'fail',
    });
    const notificationTimeoutRef = useRef(null as any);

    const showNotification = useCallback(
        (message: string, state: 'success' | 'fail', timeout: number) => {
            if (notificationTimeoutRef.current) clearTimeout(notificationTimeoutRef.current);
            setNotificationState({ open: true, message, state });
            notificationTimeoutRef.current = setTimeout(
                () => setNotificationState({ open: false, message, state }),
                timeout
            );
        },
        []
    );

    const footerSocialItems = useMemo(() => {
        return socials
            ? socials.map(social => ({
                  href: social.link,
                  imageSrc: social.imageLink,
              }))
            : [];
    }, [socials]);

    if (!copies) return <SplashScreen />;

    return (
        <div className={lang}>
            <NotificationContext.Provider value={{ showNotification }}>
                <LanguageContext.Provider value={{ lang, setLang }}>
                    <ScrollToTop />
                    <Navbar
                        darkLogoColor="#263238"
                        lightLogoColor="#ffffff"
                        items={[
                            { title: content[lang].wines, href: '/wines' },
                            { title: content[lang].gallery, href: '/gallery' },
                            { title: content[lang].aboutUs, href: '/#about-us' },
                            { title: content[lang].contact, href: '/#contact' },
                        ]}
                    />
                    <Switch>
                        <Route
                            path="/wines"
                            render={props => <WineBrowserPage copies={copies} {...props} />}
                            exact
                        />
                        <Route path="/gallery" render={props => <GalleryPage {...props} />} exact />
                        <Route
                            path="/wine/:id"
                            render={props => (
                                <WinePage copies={copies} {...props} {...props.match.params} />
                            )}
                            exact
                        />
                        <Route path="/404" render={props => <NotFoundPage {...props} />} exact />
                        <Route render={props => <HomePage copies={copies} {...props} />} />
                    </Switch>
                    <Footer
                        description={copies[lang].footer_description}
                        logoSrc={lightLogoSVG}
                        navigationTitle={content[lang].navigation}
                        navItems={[
                            { title: content[lang].wines, href: '/wines' },
                            { title: content[lang].gallery, href: '/gallery' },
                            { title: content[lang].aboutUs, href: '/#about-us' },
                            { title: content[lang].contact, href: '/#contact' },
                        ]}
                        socialItems={footerSocialItems}
                    />
                    <Notification {...notificationState} />
                </LanguageContext.Provider>
            </NotificationContext.Provider>
        </div>
    );
};
