import { Grid } from '@material-ui/core';
import React from 'react';
import Fade from 'react-reveal/Fade';
import { WineItem } from './WineItem';

export interface WineItem {
    id: string;
    name: string | null;
    specification: string | null;
    imgSrc: string | null;
}

interface Props {
    title: string | null;
    wines: WineItem[];
}

export const WineList: React.FC<Props> = ({ title, wines }) => {
    return (
        <div className="gw-wine-list-wrapper gw-section-md">
            <Fade>
                <h3 className="gw-wine-list-title">{title}</h3>
            </Fade>
            <div className="gw-wine-list">
                <Grid container spacing={8}>
                    {wines.map(wine => (
                        <Grid item xs={12} md={6} lg={4} key={wine.id}>
                            <WineItem wine={wine} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};
