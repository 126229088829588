import React from 'react';
import { Loading } from './Loading';

interface Props {
    border?: boolean;
}

export const LoadingSection: React.FC<Props> = ({ border }) => {
    return (
        <div className={`gw-loading-section ${border ? 'border' : ''}`}>
            <Loading />
        </div>
    );
};
