import React from 'react';

export interface CheckboxOption {
    value: string;
    label: string | null;
}

interface Props {
    value: string[];
    options: CheckboxOption[];
    onChange: (newValue: string[]) => void;
}

export const CheckboxSelect: React.FC<Props> = ({ value, options, onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = value.includes(e.target.value)
            ? value.filter(val => val !== e.target.value)
            : [...value, e.target.value];
        onChange(newValue);
    };

    return (
        <div className="gw-checkbox-select-wrapper">
            {options.map(option => {
                return option.label ? (
                    <div className="gw-checkbox-select-option-wrapper" key={option.value}>
                        <input
                            type="checkbox"
                            id={`checkbox-${option.label}-${option.value}`}
                            checked={value.includes(option.value)}
                            onChange={handleChange}
                            value={option.value}
                            className="gw-checkbox"
                        />
                        <label htmlFor={`checkbox-${option.label}-${option.value}`}>
                            {option.label}
                        </label>
                    </div>
                ) : null;
            })}
        </div>
    );
};
