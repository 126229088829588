import React, { useState } from 'react';
import ChevronSVG from '../assets/img/chevron-up.svg';

interface Props {
    title: string;
}

export const SidebarItemCollapsible: React.FC<Props> = ({ title, children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="gw-sidebar-item-collapsible">
            <div className="gw-sidebar-item-collapsible-header" onClick={handleCollapse}>
                <h5 className="gw-sidebar-item-collapsible-title">{title}</h5>
                <img
                    src={ChevronSVG}
                    alt="chevron"
                    className="gw-sidebar-item-collapsible-chevron"
                    style={{ transform: `${isCollapsed ? '' : 'rotate(180deg)'}` }}
                />
            </div>
            {!isCollapsed && <div className="gw-sidebar-item-collapsible-child">{children}</div>}
        </div>
    );
};
