import React, { useState, useEffect, useCallback } from 'react';
import Masonry from 'react-masonry-css';
import Fade from 'react-reveal/Fade';

interface Props {
    images: string[];
}

export const modulo = (n: number, m: number) => ((n % m) + m) % m;

export const Gallery: React.FC<Props> = ({ images }) => {
    const [openedImage, setOpenedImage] = useState<{ imageSrc: string; idx: number } | null>(null);

    const open = (imageSrc: string, idx: number) => {
        document.body.style.overflow = 'hidden';
        setOpenedImage({
            imageSrc,
            idx,
        });
    };

    const close = () => {
        document.body.style.overflow = 'auto';
        setOpenedImage(null);
    };

    const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) close();
    };

    const handleArrowKeys = useCallback(
        (e: KeyboardEvent) => {
            if (!openedImage) return;

            if (e.keyCode === 39) {
                const nextImageIdx = modulo(openedImage.idx + 1, images.length);
                setOpenedImage({
                    imageSrc: images[nextImageIdx],
                    idx: nextImageIdx,
                });
            } else if (e.keyCode === 37) {
                const nextImageIdx = modulo(openedImage.idx - 1, images.length);
                setOpenedImage({
                    imageSrc: images[nextImageIdx],
                    idx: nextImageIdx,
                });
            } else if (e.keyCode === 27) {
                close();
            }
        },
        [images, openedImage]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleArrowKeys);
        return () => window.removeEventListener('keydown', handleArrowKeys);
    }, [handleArrowKeys]);

    return (
        <section className="gw-gallery-wrapper gw-section-md">
            {openedImage && (
                <Fade duration={500}>
                    <div className="gw-image-zoom-wrapper" onClick={handleOutsideClick}>
                        <span className="gw-image-zoom-close-btn" onClick={close}>
                            X
                        </span>
                        <img
                            src={openedImage.imageSrc}
                            alt="gallery view"
                            className="gw-image-zoom-img"
                        />
                    </div>
                </Fade>
            )}
            <div className="gw-container-lg">
                <div className="gw-gallery-images-wrapper">
                    <Masonry
                        breakpointCols={{ default: 3, 768: 1, 1023: 2 }}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {images.map((image, idx) => (
                            <div className="gw-gallery-image-wrapper" key={idx}>
                                <img
                                    className="gw-gallery-image"
                                    alt="gallery"
                                    src={image}
                                    onClick={() => open(image, idx)}
                                />
                            </div>
                        ))}
                    </Masonry>
                </div>
            </div>
        </section>
    );
};
