import React from 'react';
import chevronLeftSVG from '../assets/img/chevron-left.svg';
import chevronRightSVG from '../assets/img/chevron-right.svg';

interface Props {
    onNext: () => void;
    onPrev: () => void;
}

export const NavButtons: React.FC<Props> = ({ onNext, onPrev }) => {
    return (
        <div className="gw-nav-buttons">
            <button className="gw-nav-btn" onClick={onPrev}>
                <img src={chevronLeftSVG} alt="back" />
            </button>
            <button className="gw-nav-btn" onClick={onNext}>
                <img src={chevronRightSVG} alt="next" />
            </button>
        </div>
    );
};
