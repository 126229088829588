import { Grid } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import Fade from 'react-reveal/Fade';

interface Pairing {
    name: string | null;
    imageSrc: string | null;
}

interface Color {
    name: string | null;
    hex: string | null;
}

interface Medal {
    name: string | null;
    year: number | null;
}

interface Grape {
    name: string | null;
    region: string | null;
    hex: string | null;
    percentage: number | null;
}

interface Location {
    latitude: number | null;
    longitude: number | null;
    name: string | null;
    phone: string | null;
    address: string | null;
}

interface Titles {
    type: string;
    region: string;
    alcoholPercentage: string;
    method: string;
    color: string;
    year: string;
    grape: string;
    pairings: string;
    medals: string;
    locations: string;
}

interface Wine {
    name: string | null;
    specification: string | null;
    description: string | null;
    region: string | null;
    stock: number | null;
    imageSrc: string | null;
    alcoholPercentage: number | null;
    method: string | null;
    color: Color;
    pairings: Pairing[];
    medals: Medal[];
    grapes: Grape[];
    locations: Location[];
    brand: string | null;
    brandLogoSrc: string | null;
    year: number | null;
    type: string | null;
    id: string;
}

interface Props {
    wine: Wine;
    titles: Titles;
}

interface IProps {
    title: string;
    value: string | number | null;
}

const NutritionItem: React.FC<IProps> = ({ title, value }) => {
    return value ? (
        <div className="gw-nutrition-item">
            <h5 className="gw-nutrition-item-title">{title}</h5>
            <div className="gw-nutrition-item-hr"></div>
            <h6 className="gw-nutrition-item-value">{value}</h6>
        </div>
    ) : null;
};

export const WineDetailed: React.FC<Props> = ({ wine, titles }) => {
    const defaultCenter = useMemo(() => {
        if (!wine.locations[0]) return { lat: 41.7211187, lng: 44.7747026 };
        return { lat: wine.locations[0].latitude, lng: wine.locations[0].longitude };
    }, [wine]);

    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [mapZoom, setMapZoom] = useState(14);

    const handleLocationClick = (lat: number | null, lng: number | null) => {
        setMapZoom(17);
        setMapCenter({ lat, lng });
    };

    return (
        <section className="gw-wine-detailed-wrapper gw-section-lg">
            <div className="gw-container-lg">
                <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <div className="gw-wine-detailed-info">
                            <div className="gw-brand-info gw-wine-detailed-header">
                                <div className="gw-brand-header">
                                    <div>
                                        <h3 className="gw-wine-detailed-name">{wine.name}</h3>
                                        <p className="gw-wine-detailed-specification">
                                            {wine.specification}
                                        </p>
                                    </div>
                                    <img
                                        src={wine.brandLogoSrc || ''}
                                        alt="logo"
                                        className="gw-brand-logo"
                                    />
                                </div>
                                <div className="gw-brand-hr">
                                    <Fade left delay={0}>
                                        <hr />
                                    </Fade>
                                </div>
                                <p className="gw-brand-description">{wine.description}</p>
                            </div>
                            <div className="gw-wine-detailed-nutrition-val">
                                <NutritionItem title={titles.type} value={wine.type} />
                                <NutritionItem title={titles.region} value={wine.region} />
                                <NutritionItem
                                    title={titles.alcoholPercentage}
                                    value={wine.alcoholPercentage}
                                />
                                <NutritionItem title={titles.method} value={wine.method} />
                                <NutritionItem title={titles.color} value={wine.color.name} />
                                <NutritionItem
                                    title={titles.grape}
                                    value={wine.grapes.map(grape => grape.name).join(', ')}
                                />
                                <NutritionItem title={titles.year} value={wine.year} />
                            </div>
                            {!!wine.pairings.length && (
                                <div className="gw-wine-detailed-pairings-wrapper-outer">
                                    <h5 className="gw-wine-detailed-title">{titles.pairings}</h5>
                                    <div className="gw-wine-detailed-pairings-wrapper">
                                        {wine.pairings.map((pairing, idx) => (
                                            <div
                                                key={idx}
                                                className="gw-wine-detailed-pairing-item"
                                            >
                                                <img
                                                    src={pairing.imageSrc || ''}
                                                    alt={pairing.name || ''}
                                                    className="gw-wine-detailed-pairing-image"
                                                    draggable="false"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {!!wine.medals.length && (
                                <div className="gw-wine-detailed-medals-wrapper">
                                    <h5 className="gw-wine-detailed-title">{titles.medals}</h5>
                                    <h6 className="gw-wine-detailed-medals">
                                        {wine.medals
                                            .map(medal => `${medal.name} ${medal.year}`)
                                            .join(', ')}
                                    </h6>
                                </div>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={5}>
                        <div className="gw-brand-wine-image-wrapper gw-wine-detailed-image-wrapper">
                            <div className="gw-brand-wines">
                                <div className="gw-brand-wine-image-slider">
                                    <img
                                        src={wine.imageSrc || ''}
                                        alt={wine.name || ''}
                                        draggable="false"
                                        className="gw-brand-wine-image"
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    {!!wine.locations.length && (
                        <Grid item xs={12}>
                            <div className="gw-wine-detailed-location-wrapper-outer">
                                <h5 className="gw-wine-detailed-title">{titles.locations}</h5>
                                <GMap
                                    zoom={mapZoom}
                                    center={mapCenter}
                                    markers={wine.locations}
                                    loadingElement={<div />}
                                    containerElement={
                                        <div className="gw-wine-detailed-map-wrapper" />
                                    }
                                    googleMapURL={
                                        'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBHn7QK2v9uz80xx1-2WL3cmv2JnzGJqoA'
                                    }
                                    mapElement={<div style={{ height: '100%' }} />}
                                />
                                <div className="gw-wine-detailed-map-locations-wrapper">
                                    <Grid container spacing={2}>
                                        {wine.locations.map((location, idx) => (
                                            <Grid item md={4} xs={12} key={idx}>
                                                <div
                                                    className="gw-wine-detailed-location"
                                                    onClick={() =>
                                                        handleLocationClick(
                                                            location.latitude,
                                                            location.longitude
                                                        )
                                                    }
                                                >
                                                    <h5>{location.name}</h5>
                                                    <p>{location.address}</p>
                                                    <p>{location.phone}</p>
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
        </section>
    );
};

const GMap = withScriptjs(
    withGoogleMap(
        ({
            markers,
            center,
            zoom,
        }: {
            zoom: number;
            markers: Location[];
            center: { lat: number | null; lng: number | null };
        }) => (
            <GoogleMap
                center={center}
                zoom={zoom}
                options={{
                    disableDefaultUI: true,
                    zoomControl: true,
                    styles: [
                        {
                            featureType: 'administrative',
                            elementType: 'geometry',
                            stylers: [
                                {
                                    visibility: 'off',
                                },
                            ],
                        },
                        {
                            featureType: 'poi',
                            stylers: [
                                {
                                    visibility: 'off',
                                },
                            ],
                        },
                        {
                            featureType: 'road',
                            elementType: 'labels.icon',
                            stylers: [
                                {
                                    visibility: 'off',
                                },
                            ],
                        },
                        {
                            featureType: 'transit',
                            stylers: [
                                {
                                    visibility: 'off',
                                },
                            ],
                        },
                    ],
                }}
            >
                {markers.map((marker, idx) => (
                    <Marker
                        position={{ lat: marker.latitude, lng: marker.longitude }}
                        key={idx}
                        label={marker.name}
                    />
                ))}
            </GoogleMap>
        )
    )
);
