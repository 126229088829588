import React, { useContext, useMemo } from 'react';
import { LanguageContext } from '../App';
import { WineCarousel } from '../components/WineCarousel';
import { WineDetailed } from '../components/WineDetailed';
import { content } from '../content';
import { useWine, useWines, Copies } from '../hooks/wines';
import { LoadingSection } from '../utils/LoadingSection';
import { usePageView } from '../hooks/pageView';

interface Props {
    id: string;
    copies: Copies;
}

export const WinePage: React.FC<Props> = ({ id, copies }) => {
    usePageView();
    const wine = useWine(id);
    const wines = useWines();
    const { lang } = useContext(LanguageContext);

    const brandShowcaseWines = useMemo(() => {
        return wines?.map(wine => ({
            description: wine[`description_${lang}`] as string | null,
            name: wine[`name_${lang}`] as string | null,
            imgSrc: wine.mainImageLink ? wine.mainImageLink : null,
            id: wine.id,
            brandId: wine.brands[0].id,
        }));
    }, [lang, wines]);

    const specification = useMemo(() => {
        return wine?.wine_type && wine.color
            ? `${wine.color[`name_${lang}`]} ${wine.wine_type[`name_${lang}`]} ${
                  content[lang].wine
              }`
            : '';
    }, [lang, wine]);

    const pairings = useMemo(() => {
        return wine
            ? wine.pairings.map(pairing => ({
                  imageSrc: pairing.imageLink,
                  name: pairing[`name_${lang}`],
              }))
            : [];
    }, [lang, wine]);

    const medals = useMemo(() => {
        return wine
            ? wine.medals.map(medal => ({
                  name: medal[`name_${lang}`],
                  year: medal.year,
              }))
            : [];
    }, [lang, wine]);

    const grapes = useMemo(() => {
        return wine
            ? wine.grapes.map(grape => ({
                  hex: grape.hex_color,
                  name: grape[`name_${lang}`],
                  percentage: grape.percentage,
                  region: grape[`region_${lang}`],
              }))
            : [];
    }, [lang, wine]);

    const locations = useMemo(() => {
        return wine
            ? wine.locations.map(location => ({
                  latitude: location.geoJSON.coordinates[1],
                  longitude: location.geoJSON.coordinates[0],
                  name: location[`name_${lang}`],
                  phone: location.number,
                  address: location[`address_${lang}`],
              }))
            : [];
    }, [lang, wine]);

    return (
        <>
            {wine ? (
                <WineDetailed
                    wine={{
                        id: wine.id,
                        name: wine[`name_${lang}`],
                        specification,
                        description: wine[`description_${lang}`],
                        region: wine[`region_${lang}`],
                        stock: wine.stock,
                        imageSrc: `${wine.mainImageLink}`,
                        alcoholPercentage: wine.alcohol_amount,
                        method: wine.wine_method ? wine.wine_method[`name_${lang}`] : null,
                        brand: wine.brands[0][`name_${lang}`],
                        brandLogoSrc: `${wine.brands[0].logoImageLink}`,
                        year: wine.bottling_year,
                        type: wine.wine_type ? wine.wine_type[`name_${lang}`] : null,
                        color: {
                            hex: wine.color && wine.color.hex_color,
                            name: wine.color && wine.color[`name_${lang}`],
                        },
                        pairings,
                        medals,
                        grapes,
                        locations,
                    }}
                    titles={{
                        type: content[lang].type,
                        region: content[lang].region,
                        alcoholPercentage: content[lang].alcoholPercentage,
                        method: content[lang].method,
                        color: content[lang].color,
                        year: content[lang].year,
                        grape: content[lang].grape,
                        pairings: content[lang].pairings,
                        medals: content[lang].medals,
                        locations: content[lang].locations,
                    }}
                />
            ) : (
                <LoadingSection border />
            )}

            {brandShowcaseWines ? (
                <WineCarousel
                    autoPlaySpeed={5}
                    title={copies[lang].winecarousel_title}
                    description={copies[lang].winecarousel_description}
                    wines={brandShowcaseWines}
                />
            ) : (
                <LoadingSection />
            )}
        </>
    );
};
