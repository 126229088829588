import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Grid } from '@material-ui/core';
import { content } from '../content';
import { LanguageContext } from '../App';

interface Values {
    name: string;
    email: string;
    message: string;
}

interface Props {
    title: string | null;
    description: string | null;
    placeholders: Values;
    values: Values;
    onInputChange: (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    onSubmit: () => void;
}

export const Contact: React.FC<Props> = ({
    title,
    description,
    placeholders,
    values,
    onInputChange,
    onSubmit,
}) => {
    const { lang } = useContext(LanguageContext);

    return (
        <section className="gw-contact-wrapper gw-section-lg" id="contact">
            <div className="gw-container-lg">
                <Fade delay={0}>
                    <h3 className="gw-contact-title">{title}</h3>
                </Fade>
                <Fade delay={300}>
                    <p className="gw-contact-description">{description}</p>
                </Fade>
                <div className="gw-contact-input-wrapper">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <input
                                type="text"
                                className="gw-contact-input"
                                onChange={onInputChange}
                                value={values.email}
                                placeholder={placeholders.email}
                                name="email"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <input
                                type="text"
                                className="gw-contact-input"
                                onChange={onInputChange}
                                value={values.name}
                                placeholder={placeholders.name}
                                name="name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <textarea
                                className="gw-contact-textarea"
                                value={values.message}
                                onChange={onInputChange}
                                name="message"
                                placeholder={placeholders.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="gw-contact-button-wrapper">
                                <button className="gw-button" onClick={onSubmit}>
                                    {content[lang].send}
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </section>
    );
};
