import React, { useContext, useMemo, useState } from 'react';
import { WinesController } from '../api/controller';
import { LanguageContext, NotificationContext } from '../App';
import vineYardIMG from '../assets/img/vineyard.jpg';
import { AboutUs } from '../components/AboutUs';
import { BrandShowcase } from '../components/BrandShowcase';
import { Contact } from '../components/Contact';
import { MainScreen } from '../components/MainScreen';
import { WineCarousel } from '../components/WineCarousel';
import { content } from '../content';
import { Copies, useWineBrands, useWines } from '../hooks/wines';
import { LoadingSection } from '../utils/LoadingSection';
import { usePageView } from '../hooks/pageView';

interface Props {
    copies: Copies;
}

export const HomePage: React.FC<Props> = ({ copies }) => {
    usePageView();
    const wines = useWines();
    const brands = useWineBrands();
    const { lang } = useContext(LanguageContext);
    const { showNotification } = useContext(NotificationContext);
    const [contactValues, setContactValues] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleContactInputChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setContactValues({ ...contactValues, [e.target.name]: e.target.value });
    };

    const handleContactSubmit = () => {
        if (!validateEmail(contactValues.email)) {
            showNotification(content[lang].contactErrorEmail, 'fail', 10000);
        } else if (contactValues.name.length < 1) {
            showNotification(content[lang].contactErrorName, 'fail', 10000);
        } else if (contactValues.message.length < 1) {
            showNotification(content[lang].contactErrorMessage, 'fail', 10000);
        } else {
            const controller = new WinesController();
            controller
                .postContact(contactValues)
                .then(res => {
                    showNotification(content[lang].contactSuccess, 'success', 10000);
                    setContactValues({ name: '', email: '', message: '' });
                })
                .catch(e => {
                    showNotification(content[lang].contactErrorUnexpected, 'fail', 10000);
                    console.error(e);
                });
        }
    };

    const brandShowcaseWines = useMemo(() => {
        return wines?.map(wine => ({
            description: wine[`description_${lang}`] as string | null,
            name: wine[`name_${lang}`] as string | null,
            imgSrc: wine.mainImageLink ? wine.mainImageLink : null,
            id: wine.id,
            brandId: wine.brands[0].id,
        }));
    }, [lang, wines]);

    return (
        <>
            <MainScreen
                header={copies[lang].calltoaction_header}
                description={copies[lang].calltoaction_description}
                callToAction={{
                    text: copies[lang].calltoaction_button,
                    href: '/wines',
                }}
                backgroundSrc={`${copies[lang].callToActionImageLink}` || vineYardIMG}
            />
            {brands && brandShowcaseWines ? (
                brands.map((brand, idx) => (
                    <BrandShowcase
                        key={brand.id}
                        callToAction={content[lang].detailed}
                        brandName={brand[`name_${lang}`]}
                        brandDescription={brand[`description_${lang}`]}
                        brandLogoSrc={`${brand.logoImageLink}`}
                        wines={brandShowcaseWines.filter(wine => wine.brandId === brand.id)}
                        slideInterval={10}
                        flip={!!(idx % 2)}
                    />
                ))
            ) : (
                <LoadingSection border />
            )}
            {brandShowcaseWines ? (
                <WineCarousel
                    autoPlaySpeed={5}
                    title={copies[lang].winecarousel_title}
                    description={copies[lang].winecarousel_description}
                    wines={brandShowcaseWines}
                />
            ) : (
                <LoadingSection border />
            )}
            <AboutUs
                imageSrc={`${copies[lang].aboutUsImageLink}`}
                title={copies[lang].aboutus_title}
                descriptionColOne={copies[lang].aboutus_description_one}
                descriptionColTwo={copies[lang].aboutus_description_two}
            />
            <Contact
                title={copies[lang].contact_title}
                description={copies[lang].contact_description}
                placeholders={{
                    name: content[lang].yourName,
                    email: 'youremail@mail.com',
                    message: content[lang].message,
                }}
                values={contactValues}
                onInputChange={handleContactInputChange}
                onSubmit={handleContactSubmit}
            />
        </>
    );
};

const validateEmail = (email: string) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
