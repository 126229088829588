import { Grid } from '@material-ui/core';
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

interface NavItem {
    title: string;
    href: string;
}

interface socialItem {
    imageSrc: string | null;
    href: string | null;
}

interface Props {
    logoSrc: string;
    description: string | null;
    navigationTitle: string;
    navItems: NavItem[];
    socialItems: socialItem[];
}

export const Footer: React.FC<Props> = ({
    description,
    logoSrc,
    navItems,
    navigationTitle,
    socialItems,
}) => {
    return (
        <footer className="gw-footer-wrapper gw-section-md">
            <div className="gw-container-lg">
                <Grid container spacing={0}>
                    <Grid item lg={5} xs={12}>
                        <div className="gw-footer-info">
                            <img src={logoSrc} alt="logo" className="gw-footer-logo" />
                            <p className="gw-footer-description">{description}</p>
                        </div>
                    </Grid>
                    <Grid item lg={1} xs={12}></Grid>
                    <Grid item lg={2} xs={6}>
                        <h5 className="gw-footer-nav-title">{navigationTitle}</h5>
                        <div className="gw-footer-navigation-wrapper">
                            {navItems.map((item, idx) => (
                                <div key={idx} className="gw-footer-navigation-item">
                                    <Link to={item.href}>{item.title}</Link>
                                </div>
                            ))}
                        </div>
                    </Grid>
                    <Grid item lg={1} xs={1}></Grid>
                    <Grid item lg={3} xs={5}>
                        <div className="gw-footer-social-wrapper">
                            {socialItems.map((item, idx) => {
                                return item.imageSrc && item.href ? (
                                    <div className="gw-footer-social-item" key={idx}>
                                        <a
                                            href={item.href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className="gw-footer-social-item-image"
                                                alt="social link"
                                                src={item.imageSrc}
                                            ></img>
                                        </a>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </footer>
    );
};
